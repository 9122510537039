/* --------------herfra PrintMenu in Booking.js--------------- */
.container-printMenu {
  list-style: none;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: flex-end;
}

.printMenu {
  justify-content: end;
  margin-left: 92%
}
/* --------------herfra booking.js--------------- */
.container-1 {
  justify-content: flex-start;
  display: flex;
  /*
  max-width: 1400px;
  margin: 40px auto 0 auto;
  align-items: flex-start;
   align-items: center;
   flex-direction: column;

   display: flex;
  justify-content: space-between;
  */
}

.box-1 {
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 20%;
}

.box-2 {
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 43%;
}

.box-3 {
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 35%;
}
/* --------------herfra bookings.js--------------- */
.container-2 {
  list-style: none;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: flex-end;
}

.booking-box-1 {
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 100%;
}

/* --------------herfra editpacking.js--------------- */

.container-edit {
  justify-content: flex-start;
}
.box-spiritus {
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 100%;
}

.juiceAndpure {
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 100%;
}

.specialvarer {
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 100%;
}

/* --------------herfra menuprint i booking--------------- */

.container-menuprint {
  list-style: none;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: flex-end;
  position: absolute;
  right: 220px;
  top: 10px; 
}




/* --------------herfra resp.--------------- */

@media (min-width: 1200px) {
  .container-1 {
    justify-content: flex-start;
    display: flex;
    justify-content: space-between;
  }

  .container-edit {
    justify-content: flex-start;
    display: flex;
    justify-content: space-between;
  }
}


